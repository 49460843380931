/* eslint-disable object-curly-spacing */
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const GbiBridged = (): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { backgroundImageHome } = useStaticQuery(
    graphql`
      query {
        backgroundImageHome: file(relativePath: {eq: "triceonic-stategieumsetzung-digitalisiert-header.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              width: 2000 
              quality: 50
              webpOptions: {quality: 70}
            )
          }
        }
      }
    `
  );

  const image = getImage(backgroundImageHome as ImageDataLike);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      className="masthead headerWithText"
      preserveStackingContext
    >
      <div className="innerHeader">
        <h1>Ein Unternehmen. Viele Aufgaben. Volle Kraft.</h1>
        <div><p>Bringe Dein volles Potential auf die Straße und richte es zielsicher auf Zukunftsthemen aus.</p></div>
      </div>
    </BackgroundImage >
  );
};

export default GbiBridged;
