import * as React from 'react';
import HeaderHome from '../components/HeaderHome';
import '../styles/grid.css';
import '../styles/styles.css';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faRankingStar, faUsersLine, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { StaticImage } from 'gatsby-plugin-image';

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
      }
    }
  }
`;

const IndexPage = (props: unknown): React.ReactElement => {

  return (
    <Layout>
      <>
        <div className='imageWrapper clipPathBottom'>
          <HeaderHome />
        </div>
        <div className='container imageText'>
          <div className='row'>
            <div className='col-6'>
              <h1>Leistungsstarke Strategieumsetzung</h1>
              <p>Kennen Sie das Gefühl nach einer Besprechung, wenn Ziele und Aufgaben klar und abgestimmt sind? In diesem Moment ist die Energie hoch und alle legen voller Kraft los. Das ist leistungsstarke Strategieumsetzung.</p>
              <p>Damit Sie diese Leistung dauerhaft halten können, entwickeln wir nachhaltige Softwarelösungen, die Mitarbeiter begeistern und Prozesse dauerhaft verankern.</p>
            </div>
            <div className='col-6'>
              <StaticImage src='../images/leistungsstarke-strategieumsetzung-triceonic.png' alt='Leistungsstarke Strategieumsetzung' placeholder='none' />
            </div>
          </div>
        </div>
        <div className='eyeCatcher clipPathTop' id='innovation'>
          <div className='container eyeCatcherCentered'>
            <div className='row'>
              <div className='col-9'>
                <p>Eine einzigartige Arbeitsweise sorgt für gelebte Strategieumsetzung.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='futureModel'>
              <div className='col-4'>
                <FontAwesomeIcon className='fontAwesomeIcon' icon={faRankingStar} />
                <h2>digital</h2>
                <p>Was wäre, wenn Sie Kommunikationserfolg messen könnten? Erkennen Sie, ob Ihre Strategie in der Umsetzung angekommen ist, wenn mit der Planung von operativen Aufgaben für strategische Ziele begonnen wird.</p>
                <p>Wenn Strategieumsetzung lückenlos digital abgebildet ist, dann können Sie datengetrieben entscheiden. Und das endet nicht beim Kommunikationserfolg: Erfahren Sie, wann ein Thema gesättigt ist, ob organisatorische Veränderungen nötig sind oder wo unterschiedliche Meinungen herrschen.</p>
              </div>

              <div className='col-4'>
                <FontAwesomeIcon className='fontAwesomeIcon' icon={faLink} />
                <h2>iterativ</h2>
                <p>Suchen Sie nach etwas, das Ihnen hilft die Vielzahl von Entscheidungen, die Sie tagtäglich in kleine Kästchen eintragen, belastbar zu ermitteln und kontinuierlich zu warten? Profitieren Sie von einer iterativen Arbeitsweise, die in optimalen Zeitabständen ihre Bewertung aufnimmt.</p>
                <p>In kurzen Sitzungen erstellen Sie somit ein vollständiges Modell der Strategieumsetzung, das Sie mühelos aktuell halten. Verbessern Sie ihre Ergebnisse weiter, in dem Sie über kooperative Funktionen Experten einbinden.</p>
              </div>

              <div className='col-4'>
                <FontAwesomeIcon className='fontAwesomeIcon' icon={faUsersLine} />
                <h2>direkt</h2>
                <p>Arbeiten Sie agil und wünschen sich einen Weg Entscheidungen ohne Umwege in den Arbeitsprozess einfließen zu lassen? Treffen Sie autonome operative und strategische Entscheidungen und führen Sie sie automatisch in einem Arbeitsplan zusammen.</p>
                <p>Haben Sie die operativ wichtigsten Aufgaben gefunden, dann werden sie im Arbeitsplan ganz oben gereiht. Gewichten Sie aus strategischen Gründen ein Ziel höher als ein anderes, dann finden sich im Arbeitsplan auch mehr Aufgaben zu diesem Ziel.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='eyeCatcher clipPathBottom' id='function'>
          <div className='container eyeCatcherCentered'>
            <div className='row'>
              <div className='col-9'>
                <p>Drei Funktionen füllen die Lücke zwischen strategischer und operative Ebene.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='container imageText gifs'>
            <div className='row'>
              <div className='col-4'>
                <img src='Verlinken.gif' />
              </div>
              <div className='col-9'>
                <h2>verlinken</h2>
                <p>Verlinken Sie operative Aufgaben zu strategischen Zielen und stellen Sie so die Verbindung zwischen verschiedenen Flughöhen her.</p>
                <p>Über die so entstandenen Verbindungen können Ressourcen zielgenau verteilt und Ergebnisse aggregiert werden. Aufgaben und Ziele, die über mehrere Ebenen verlinkt sind, stellen das Rückgrat im Modell der Strategieumsetzung dar.</p>
                <p>Ein iterativer Prozess kümmert sich darum, dass das Verlinken mit optimalen Ressourceneinsatz erfolgt.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='backgroundColorDarker'>
          <div className='container imageText gifs'>
            <div className='row'>
              <div className='col-9'>
                <h2>priorisieren</h2>
                <p>Bewerten Sie operative Aufgaben und stellen Sie sicher, dass das Wichtige zuerst erledigt wird. Priorisierung ist auch eine entscheidende Stellgröße für den iterativen Prozess, der wichtigen Aufgaben häufiger wiederholt als weniger wichtige.</p>
                <p>Mathematische Methoden helfen dabei, dass die Entscheidungen eine Hohe Güte aufweisen. Äußere Umstände, die bei ad-hoc Entscheidungen zu großen Fehlern führen, können so vermieden werden. Trend- und Clusteranalysen geben weitere Einblicke.</p>
              </div>
              <div className='col-4'>
                <img src='Priorisieren.gif' />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='container imageText gifs'>
            <div className='row'>
              <div className='col-4'>
                <img src='Verteilen.gif' />
              </div>
              <div className='col-9'>
                <h2>verteilen</h2>
                <p>Aufgaben zu verteilen ist eine strategische Entscheidung und bestimmt wie viele Aufgaben für jedes einzelne Ziel geplant werden. Im Gegensatz dazu, ist Aufgaben zu priorisieren eine operative Entscheidung und bestimmt welche Aufgaben am meisten auf ein Ziel einzahlen. Die Trennung beider Entscheidungen hilft Strategieumsetzung organisatorisch zu verankern.</p>
                <p>Die Verteilung ist eine wichtige Stellgröße und beschreibt welche Ziele im Fokus liegen. Ein Vergleich der Soll- und Ist-Verteilung kann bei Unterschieden auf notwendige organisatorische Änderungen hinweisen.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='eyeCatcher'>
          <div className='container'>
            <div className='row'>
              <div className='row col-9'>
                <p>Als Innovator für Strategieumsetzung liefern wir die Basis für dauerhaft verankerte Prozesse, die durchgängig vom Nordstern bis hin zu Sprintzielen greifen.</p>
              </div>
              <div className="col-3">
                <a href="tel:+437203712391750">
                  <button className="lightButton">
                    <FontAwesomeIcon className="fontAwesomeIcon" icon={faPhone} />
                    Jetzt anrufen!
                  </button>
                </a>
                <a href="mailto:office@triceonic.com">
                  <button className="lightButton">
                    <FontAwesomeIcon className="fontAwesomeIcon" icon={faEnvelope} />
                    E-Mail schreiben!
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout >
  );
};

export default IndexPage;
